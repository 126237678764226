import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

if (header && header.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
    }
  });
}

$(".default-select").niceSelect();

const formSelect = document.querySelector('.nice-select.default-select');
if(formSelect) {
  const placeholder = formSelect.querySelector('.current');
  const options = formSelect.querySelectorAll('.option');
  console.log(placeholder.textContent)
  if(placeholder.textContent === 'Select One') {
    placeholder.classList.add('current-placeholder');

    options.forEach(elem => {
      elem.addEventListener('click', () => {
        placeholder.classList.remove('current-placeholder');
      })
    })
  }
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".burger-menu").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".btn-get-in-touch").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    $(".burger-menu").removeClass("active");
    $(".btn-get-in-touch").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".burger-menu").click(menuToggle); //menu toggles
$(".close-burger-menu").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

if(document.querySelector('.btn-get-in-touch-close')) {
  const btnGetInTouchClose = document.querySelectorAll('.btn-get-in-touch-close');
  btnGetInTouchClose.forEach(elem => {
    elem.addEventListener('click', () => {
      if(document.querySelector('.burger-menu').classList.contains('active')) {
        $(".dropdown-menu").css("display", "");
        $(".header-close-wrapper").removeClass("active");
        $(".menu-item.active").removeClass("active");
        $(".navbar-nav").removeClass("active");
        $(".menu-toggle").removeClass("active");
        $(".burger-menu").removeClass("active");
        $(".btn-get-in-touch").removeClass("active");
        // LockScroll when burger open and enable when closed
        scrollLock.enablePageScroll();
      }
    })
  })
}

// gsap
gsap.registerPlugin(ScrollTrigger);
let speed = 5;
if (window.innerWidth > 1366) {
  speed = 10;
}
let tl = gsap.timeline();

const scrollingDecorToLeft = document.querySelectorAll(".decor-to-left");
if (scrollingDecorToLeft.length >= 1 && window.innerWidth >= 651) {
  scrollingDecorToLeft.forEach((decor, i) => {

    tl.to(decor, {
      xPercent: -speed,
      scrollTrigger: {
        trigger: decor,
        scrub: 0,
      },
    });
  });
}

const scrollingDecorToRight = document.querySelectorAll(".decor-to-right");
if (scrollingDecorToRight.length >= 1 && window.innerWidth >= 651) {
  scrollingDecorToRight.forEach((decor, i) => {

    tl.to(decor, {
      xPercent: speed,
      scrollTrigger: {
        trigger: decor,
        scrub: 0,
      },
    });
  });
}

const scrollingText = document.querySelectorAll(".title-banner__row");
if (scrollingText.length >= 1) {
  scrollingText.forEach((text, i) => {
    const val = i % 2 === 0 ? speed : -speed;

    tl.to(text, {
      xPercent: val,
      scrollTrigger: {
        trigger: text,
        scrub: 0,
      },
    });
  });
}

const scrollEvents = () => {
  const trueScrollTarget =
      document.getElementsByClassName("scroll-target")[0];

  if (trueScrollTarget) {
    const scrollTarget = document.querySelectorAll(".scroll-target");
    let threshold = 0.5;
    let decorsArr = [];

    if (document.getElementsByClassName("scroll-decor")[0]) {
      decorsArr = document.querySelectorAll(".scroll-decor");
    }


    if ('IntersectionObserver' in window) {
      console.log('IntersectionObserver is supported');
      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry, i) => {
              if (entry.isIntersecting) {
                if (decorsArr.length !== 0) {
                  decorsArr.forEach((elem, i) => {
                    if (entry.target === elem) {
                      elem.classList.add("animation");
                    }
                  });
                }
              }
            });
          },
          {
            threshold: 0,
            rootMargin: '0% 0% -25% 0%',
          }
      );

      scrollTarget.forEach((target, index) => {
        observer.observe(target);
      });

    }else{
      console.log("IntersectionObserver doesn't supported!");
      /*$(window).on('scroll', function(){
        let  scrollToEL = $(window).scrollTop() + $(window).height() * 0.5;

        $('.scroll-target').each((ind, el)=>{
          let isIntersecting = scrollToEL > $(el).offset().top && $(el).offset().top + $(el).height() > scrollToEL
          if(isIntersecting){

            if($(el).hasClass('video-player') && !$(el).hasClass('active')){
              $(el).addClass('active');
              el.style.width = `${videoWidth}px`;
              el.style.height = `${videoHeight}px`;
              el.style.top = `${videoTop}px`;
              el.style.borderRadius = `10px`;
            }
            if($(el).hasClass('scroll-decor') && !$(el).hasClass('animation')){
              $(el).addClass("animation");
            }
          }
        })
      })
      $(window).trigger('scroll');*/

    }

  }

};

scrollEvents();

const coreValuesSlider = document.getElementsByClassName(`core-values-slider`)[0];

if (coreValuesSlider) {

  const coreValuesSwiper = new Swiper(".core-values-slider", {
    spaceBetween: 16,
    slidesPerView: 1,
    observer: true,
    navigation: {
      nextEl: ".core-values-slider-next",
      prevEl: ".core-values-slider-prev",
    },

    // Autoplay
    /*autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },*/

    breakpoints: {
      652: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      952: {
        spaceBetween: 30,
        slidesPerView: 2,
      },
    },
  });
  /*coreValuesSwiper.autoplay.stop();*/
  SafariSwiper(coreValuesSwiper);

  /*const swiperEl = document.querySelector('.core-values-slider');
  const windowHeight = window.innerHeight;
  let autoplayStarted = false;

  function checkAutoplay() {
    const swiperTop = swiperEl.getBoundingClientRect().top;

    if (swiperTop <= windowHeight && !autoplayStarted) {
      coreValuesSwiper.autoplay.start();

      autoplayStarted = true;
    }
  }

  function handleScroll() {
    checkAutoplay();
  }

  window.addEventListener('scroll', handleScroll);*/
}

const clientCriteriaSlider = document.getElementsByClassName(`client-criteria-slider`)[0];

if (clientCriteriaSlider) {

  const clientCriteriaSwiper = new Swiper(".client-criteria-slider", {
    spaceBetween: 0,
    slidesPerView: 1,
    navigation: {
      nextEl: ".client-criteria-slider-next",
      prevEl: ".client-criteria-slider-prev",
    },

    // Autoplay
    /*autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },*/

    breakpoints: {
      651: {
        slidesPerView: 2.2,
      },
      951: {
        slidesPerView: 3,
      },
    },
  });

  SafariSwiper(clientCriteriaSwiper);
}

const lineCardsSlider = document.getElementsByClassName(`line-cards-slider`)[0];

if (lineCardsSlider) {

  const lineCardsSwiper = new Swiper(".line-cards-slider", {
    spaceBetween: 0,
    slidesPerView: 1,
    navigation: {
      nextEl: ".line-cards-slider-next",
      prevEl: ".line-cards-slider-prev",
    },

    breakpoints: {
      651: {
        slidesPerView: 2,
      },
      951: {
        slidesPerView: 3,
      },
    },
  });

  SafariSwiper(lineCardsSwiper);
}

const teamSlider = document.getElementsByClassName(`team-slider`)[0];

if (teamSlider) {

  const teamSwiper = new Swiper(".team-slider", {
    spaceBetween: 0,
    slidesPerView: 1,
    navigation: {
      nextEl: ".team-slider-next",
      prevEl: ".team-slider-prev",
    },

    breakpoints: {
      651: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 2,
      },
    },
  });

  SafariSwiper(teamSwiper);
}

const specializationSlider = document.getElementsByClassName(`specialization-slider`)[0];

if (specializationSlider) {
  const specializationSlidesArr = specializationSlider.querySelectorAll('.swiper-slide');
  const sliderButtons = specializationSlider.querySelector('.slider-buttons');
  let initialSlide = 0;
  let allowTouchMove = true;
  let autoplay = {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  };

  if(window.innerWidth >= 1300 && specializationSlidesArr.length <= 7) {
    sliderButtons.classList.add('hidden-buttons');
    initialSlide = 1;
    allowTouchMove = false;
    autoplay = false;
  } else if (window.innerWidth >= 1200 && specializationSlidesArr.length <= 6) {
    sliderButtons.classList.add('hidden-buttons');
    autoplay = false;
    initialSlide = 1;
  }

  const specializationSwiper = new Swiper(".specialization-slider", {
    spaceBetween: 24,
    slidesPerView: 2,
    observer: true,
    navigation: {
      nextEl: ".specialization-slider-next",
      prevEl: ".specialization-slider-prev",
    },

    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    breakpoints: {
      431: {
        slidesPerView: 3,
        spaceBetween: 24,
      },

      551: {
        slidesPerView: 4,
        spaceBetween: 24,
      },

      651: {
        slidesPerView: 5,
        spaceBetween: 32,
      },

      1300: {
        slidesPerView: 5,
        spaceBetween: 32,
        initialSlide: initialSlide,
        allowTouchMove: allowTouchMove,
        // Autoplay
        autoplay: autoplay,
      }
    },
  });

  SafariSwiper(specializationSwiper);
}

const clientsAboutSlider = document.getElementsByClassName(`clients-about-slider`)[0];

if (clientsAboutSlider) {

  const clientsAboutSwiper = new Swiper(".clients-about-slider", {
    spaceBetween: 0,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: ".clients-about-slider-next",
      prevEl: ".clients-about-slider-prev",
    },
  });

  SafariSwiper(clientsAboutSwiper);
}

const clientsSlider = document.getElementsByClassName("clients-slider")[0];
let clientsSwiper = null;

function initSwiper() {
  clientsSwiper = new Swiper(".clients-slider", {
    slidesPerView: 1,
    spaceBetween: 16,
    threshold: 10,
    loop: true,
    loopedSlides: 12,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".clients-slider-prev",
      nextEl: ".clients-slider-next",
    },
    breakpoints: {
      421: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    },
  });

  SafariSwiper(clientsSwiper);
}

function destroySwiper() {
  if (clientsSwiper !== null) {
    clientsSwiper.destroy();
    clientsSwiper = null;
  }
}

function handleResize() {
  if (window.innerWidth <= 901) {
    if (clientsSwiper === null) {
      initSwiper();
    }
  } else {
    destroySwiper();
  }
}

if (clientsSlider) {
  handleResize();

  window.addEventListener("resize", () => {
    handleResize();
  });
}


const lottieFirst = document.getElementsByClassName('lottie-first')[0];
if(lottieFirst) {
  let animationFirst = lottie.loadAnimation({
    container: document.querySelector(`.lottie-first`),
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: `app/public/lottie/pyxis-mid.json`,
  });
}

const lottieSecond = document.getElementsByClassName('lottie-second')[0];
if(lottieSecond) {
  let animationSecond = lottie.loadAnimation({
    container: document.querySelector(`.lottie-second`),
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: `app/public/lottie/pyxis-2.json`,
  });
}

// end gsap

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

var scroll = new SmoothScroll('a[href*="#"]');
